import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { currencyFormatUS } from '@helpers/string-helper'
import { breakPoints, colors, fonts } from '@constants/styles'
import { array, bool, shape, string } from 'prop-types'
import { PrimaryButton } from '@shared/button-types'
import { orderStatusEvent } from '@helpers/google-tag-manager'
import OrderProductInfoListItem from '../order-product-info-list-item'

const StyledList = styled.div`
  .order-product-info-list {
    .order-status-product-info-headings-main {
      font-size: ${fonts.txtMedium};
      font-weight: 600;
      width: 100%;
      padding: 0.5rem;
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        font-size: ${fonts.txtMediumUp};
      }
      .order-status-heading {
        text-align: right;
      }
    }
    .order-status-product-info-items {
      padding: 0;
    }
    .order-status-product-info-totals {
      font-weight: 600;
      padding: 0.5rem;
      font-size: ${fonts.txtSmall};
      @media only screen and (min-width: ${breakPoints.medium}) {
        font-size: ${fonts.txtMedium};
      }
      .balance {
        margin-top: 10px;
        .order-status-heading {
          border-top: double;
          border-color: ${colors.lightGrey};
          color: ${colors.red};
          font-size: ${fonts.txtMediumUp};
          font-weight: 700;
          margin-top: 10px;
          padding: 0.5rem;
          padding-top: 28px;
        }
        .balance-label {
          padding-right: 0;
        }
      }
      .order-status-heading {
        padding-right: 0.5rem;
        text-align: right;
        @media only screen and (min-width: ${breakPoints.medium}) {
          text-align: right;
        }
      }
      .sub-header {
        padding-right: 0;
      }
    }
    .hide-for-small-only {
      display: inherit;
      @media only screen and (max-width: ${breakPoints.small}) {
        display: none;
      }
    }
    .pay-online-button-container {
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
`

const OrderProductInfoList = ({ order }) => (
  <StyledList>
    {order && order.LineItems && (
      <div className="grid-x cell small-12 order-product-info-list">
        <div className="grid-x cell small-12 order-status-product-info-headings-main hide-for-small-only">
          <div className="order-status-heading cell small-12 medium-4 large-7" />
          <div className="order-status-heading cell small-12 medium-2 large-1">Qty.</div>
          <div className="order-status-heading cell small-12 medium-3 large-2">Price</div>
          <div className="order-status-heading cell small-12 medium-3 large-2">Amount</div>
        </div>
        <div className="grid-x cell small-12 order-status-product-info-items">
          {order.LineItems.LineItem.map(product => (
            <OrderProductInfoListItem product={product} key={product.Sku} />
          ))}
        </div>
        <div className="order-status-product-info-totals grid-x cell small-12">
          <div className="grid-x cell small-12">
            <div className="order-status-heading cell large-8" />
            <div className="order-status-heading sub-header cell small-7 medium-9 large-2">Sub Total</div>
            <div className="order-status-heading cell small-5 medium-3 large-2">
              {currencyFormatUS(order.subTotal, true)}
            </div>
          </div>
          <div className="grid-x cell small-12">
            <div className="order-status-heading cell large-8" />
            <div className="order-status-heading sub-header cell small-7 medium-9 large-2">Delivery & Shipping</div>
            <div className="order-status-heading cell small-5 medium-3 large-2">
              {currencyFormatUS(order.DeliveryCharge, true)}
            </div>
          </div>
          <div className="grid-x cell small-12">
            <div className="order-status-heading cell large-8" />
            <div className="order-status-heading sub-header cell small-7 medium-9 large-2">Tax</div>
            <div className="order-status-heading cell small-5 medium-3 large-2">
              {currencyFormatUS(order.TaxAmount, true)}
            </div>
          </div>
          <div className="grid-x cell small-12">
            <div className="order-status-heading cell large-8" />
            <div className="order-status-heading sub-header cell small-7 medium-9 large-2">Total</div>
            <div className="order-status-heading cell small-5 medium-3 large-2">
              {currencyFormatUS(order.OrderTotal, true)}
            </div>
          </div>
          <div className="grid-x cell small-12 balance">
            <div className="cell large-8" />
            <div className="order-status-heading balance-label cell small-7 medium-9 large-2">
              <strong>Balance:</strong>
            </div>
            <div className="order-status-heading cell small-5 medium-3 large-2">
              <strong>{currencyFormatUS(order.Balance, true)}</strong>
            </div>
          </div>
          {order.showPayonline && (
            <div className="grid-x cell small-12 pay-online-button-container">
              <PrimaryButton
                type="button"
                onClick={() => {
                  orderStatusEvent('payOnline', order)
                  // TODO: navigation need to add code to track ga data
                  navigate(`/order/payment?orderKey=${order?.orderKey}`)
                }}
              >
                Pay Online
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    )}
  </StyledList>
)

OrderProductInfoList.propTypes = {
  order: shape({
    Balance: string,
    DeliveryCharge: string,
    LineItems: array,
    OrderTotal: string,
    showPayonline: bool,
    TaxAmount: string,
  }),
}

export default OrderProductInfoList
