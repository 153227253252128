import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { getDateFull, getDateCalendarFormat } from '@helpers/date'
import { currencyFormatUS } from '@helpers/string-helper'
import { setOrder, setOrderList, setOrderNumber } from '@redux/modules/orderStatus'
import { Card, CardHeader, CardContent } from '@shared/card'
import { LoadingSpinner, LoadingSpinnerWrapper } from '@components/shared/LoadingSpinner'
import OrderProductInfoList from '../order-product-info-list'
import { getFinDepositTotal, getPaymentType, getOtherPayments, getFinancePlanDetails } from '../order-status-helper'
import Delivery from '../order-payment/delivery/delivery'
import { getOrderOnRefresh } from '../order-payment/helpers'

const StyledOrderDetails = styled.div`
  .order-details {
    padding: 1rem;
    h2 {
      font-weight: 600;
      text-transform: none;
      margin-bottom: 0.25rem;
    }
    h3 {
      font-size: ${fonts.txtMedium};
      font-weight: 600;
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        font-size: ${fonts.txtMediumUp};
      }
    }
    .primary {
      @media only screen and (min-width: ${breakPoints['large-min']}) {
        float: right;
      }
    }
    .order-status-detailed-header {
      padding: 10px;
      h1 {
        color: ${colors.darkerGrey};
        font-size: 2em;
        font-weight: 300;
        padding: 0;
      }
      .order-number {
        font-weight: 600;
      }
    }
    .order-status-payment-history {
      a {
        color: ${colors.primary};
        &:hover {
          text-decoration: underline;
        }
      }
      .medium-6 {
        padding: 0.5rem;
      }
      .payment-section {
        padding: 0.35rem 0;
        width: 100%;
        button {
          color: ${colors.primary};
          &:hover {
            text-decoration: underline;
          }
        }
        #other-payment-details-accordion,
        #finance-details-accordion {
          .payment {
            padding-top: 0.5rem;
            .small-12 {
              font-size: ${fonts.txtSmall};
            }
          }
        }
      }
    }
    .order-status-delivery-setup {
      padding: 1rem 0.5rem;
    }
  }
`

const OrderDetails = () => {
  const dispatch = useDispatch()
  const [order, setLocalOrder] = useState(null)
  const [expandFinance, setExpandFinance] = useState(false)
  const [expandOther, setExpandOther] = useState(false)
  const orderNumber = useSelector(state => state.orderStatus.orderNumber)
  const orderList = useSelector(state => state.orderStatus.orderList)

  useEffect(() => {
    const newOrder = orderList.filter(orderItem => orderItem.OrderNumber === orderNumber)[0]
    return setLocalOrder(newOrder)
  }, [orderList, orderNumber])

  // * On Refresh we want to check for order number and call api to update orderList
  if (typeof window !== 'undefined' && !orderList?.length && !orderNumber) {
    getOrderOnRefresh({ dispatch, setOrder, setOrderList, setOrderNumber })
  }

  const otherPayments = getOtherPayments(order)
  const financePlanDetails = getFinancePlanDetails(order)
  const synchronyDeposits = order?.FinDeposits && order?.FinDeposits?.Deposit.filter(dep => dep.CashCode === 'RGE')

  if (typeof window !== 'undefined' && order?.orderKey) {
    window.sessionStorage.setItem('orderKey', order.orderKey)
    window.sessionStorage.setItem('orderNumber', order.OrderNumber)
  }

  return (
    <StyledOrderDetails>
      {!isEmpty(order) && (
        <div className="grid-x cell small-12 order-details">
          <div className="grid-x small-12 order-status-detailed-header">
            <div className="cell small-12 medium-6 large-6">
              <h1>ORDER DETAILS</h1>
            </div>
            <div>
              <p className="order-number">{`Order # ${order.OrderNumber}`}</p>
              <p>{`Ordered on ${getDateFull(order.OrderDate)}`}</p>
            </div>
          </div>
          <Card>
            <CardHeader>
              <h3>Payment History</h3>
            </CardHeader>
            <CardContent>
              <div className="grid-x order-status-payment-history">
                <div className="grid-x cell small-12 medium-6 large-6">
                  {(order.Deposits || order.GCDeposits) && otherPayments.length > 0 && (
                    <div className="payment-section">
                      <div className="cell small-12">
                        <strong>Other Payments</strong>
                      </div>
                      <div className="cell small-12">
                        {order?.FinDeposits?.Deposit?.length > 0
                          ? currencyFormatUS(Number(order.OrderTotal) - getFinDepositTotal(order.FinDeposits.Deposit))
                          : currencyFormatUS(order.OrderTotal)}{' '}
                        (
                        <button
                          type="button"
                          className="other-payment-details-expander"
                          tabIndex="0"
                          aria-expanded={expandOther}
                          aria-controls="other-payment-details-accordion"
                          onClick={() => setExpandOther(!expandOther)}
                        >
                          {`${expandOther ? 'hide' : 'see'} details`}
                        </button>
                        )
                      </div>
                      <div id="other-payment-details-accordion">
                        {expandOther &&
                          otherPayments.map(dep => (
                            <div key={dep.AuthorizedDate + dep.AuthorizedAmount} className="payment">
                              {dep && (
                                <div>
                                  <div className="cell small-12"> {getDateCalendarFormat(dep.AuthorizedDate)} </div>
                                  <div className="cell small-12"> {getPaymentType(dep)} </div>
                                  {Number(dep.AuthorizedAmount) < 0 && (
                                    <div className="cell small-12">({currencyFormatUS(dep.AuthorizedAmount)})</div>
                                  )}
                                  {Number(dep.AuthorizedAmount) >= 0 && (
                                    <div className="cell small-12">{currencyFormatUS(dep.AuthorizedAmount)}</div>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {order.FinDeposits && order.FinDeposits.Deposit.length > 0 && (
                    <div className="payment-section">
                      <div className="cell small-12">
                        <strong>RTG Finance</strong>
                      </div>
                      <div className="cell small-12">
                        {currencyFormatUS(getFinDepositTotal(order.FinDeposits.Deposit))} (
                        <button
                          type="button"
                          className="finance-details-expander"
                          tabIndex="0"
                          aria-expanded={expandFinance}
                          aria-controls="finance-details-accordion"
                          onClick={() => setExpandFinance(!expandFinance)}
                        >
                          {`${expandFinance ? 'hide' : 'see'} details`}
                        </button>
                        )
                      </div>
                      <div id="finance-details-accordion">
                        {expandFinance &&
                          order.FinDeposits.Deposit.map(dep => (
                            <div key={dep.AuthorizedDate + dep.AuthorizedAmount} className="payment">
                              <div className="cell small-12">{getDateCalendarFormat(dep.AuthorizedDate)}</div>
                              {Number(dep.AuthorizedAmount) < 0 && (
                                <div className="cell small-12">({currencyFormatUS(dep.AuthorizedAmount)})</div>
                              )}
                              {Number(dep.AuthorizedAmount) >= 0 && (
                                <div className="cell small-12">{currencyFormatUS(dep.AuthorizedAmount)}</div>
                              )}
                            </div>
                          ))}
                        {expandFinance && order?.FinancePlan && synchronyDeposits?.length > 0 && (
                          <div>
                            {financePlanDetails?.SiteFriendlyLabel ? (
                              <div className="cell small-12">
                                <strong>Finance Plan:</strong>&nbsp;{financePlanDetails?.SiteFriendlyLabel}(
                                {financePlanDetails?.FinancePlan})
                              </div>
                            ) : (
                              <div className="cell small-12">
                                <strong>Finance Plan:</strong>&nbsp;{order?.FinancePlan}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="cell small-12 medium-6 large-6">
                  <span>
                    <strong>Note: </strong>
                    Delivery dates on orders with an open balance are subject to change. Call Customer Care if you need
                    help at{' '}
                    {order?.storeOrder ? (
                      <a href="tel:(800)-766-6786">(800)-766-6786</a>
                    ) : (
                      <a href="tel:(888)-709-5380">(888)-709-5380</a>
                    )}
                    .
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <h3>Delivery &amp; Setup</h3>
            </CardHeader>
            <CardContent>
              <Delivery order={order} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <h3>Product Information</h3>
            </CardHeader>
            <CardContent>
              <OrderProductInfoList order={order} />
            </CardContent>
          </Card>
        </div>
      )}
      {isEmpty(order) && typeof order === 'object' && <p>Your order could not be found. </p>}
      {!orderList?.length && !orderNumber && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
    </StyledOrderDetails>
  )
}

export default OrderDetails
