import { getOrderList } from '@services/order-status'
import { announce } from '@helpers/aria-announce'
import { validatePhone } from '@helpers/string-helper'
import { getFinancePlansByOrder } from '@helpers/finance'

export const getRelatedOrders = ({
  e,
  orderId,
  phoneNumber,
  loading,
  setOrderStatusState,
  setLoading,
  ref,
  setError,
}) => {
  e.preventDefault()
  if (!loading) {
    setLoading(true)
    const validPhone = validatePhone(phoneNumber)
    if (validPhone) {
      getOrderList({ orderId, phoneNumber: phoneNumber.replace(/[^0-9]/g, '') })
        .then(orderList => {
          setOrderStatusState({ orderList })
          if (ref) window.scrollTo(0, ref.current.clientHeight)
          setError(null)
          setLoading(false)
        })
        .catch(err => {
          const error =
            err && err.message && err.message.data && err.message.data.error && err.response.data.error.message
              ? err.response.data.error.message
              : 'We are unable to retrieve your order, please try again later.'
          announce(error)
          setOrderStatusState({ orderList: {} })
          setError(error)
          setLoading(false)
        })
    } else {
      const error = 'Invalid phone number.'
      announce(error)
      setOrderStatusState({ orderList: {} })
      setError(error)
      setLoading(false)
    }
  }
}

export const getFinDepositTotal = (deposits = []) =>
  deposits.reduce((acc, curr) => acc + Number(curr.AuthorizedAmount), 0)

export const getOtherPayments = order => {
  let otherPayments = []
  if (order) {
    if (order.Deposits && order.Deposits.Deposit.length > 0) {
      otherPayments = [...otherPayments, ...order.Deposits.Deposit]
    }
    if (order.GCDeposits && order.GCDeposits.Deposit.length > 0) {
      otherPayments = [...otherPayments, ...order.GCDeposits.Deposit]
    }
  }
  return otherPayments
}

export const getFinancePlanDetails = order => {
  const financePlanDetails = { FinancePlan: '', SiteFriendlyLabel: '' }
  if (order) {
    financePlanDetails.FinancePlan = order.FinancePlan
    const financePlans = getFinancePlansByOrder(order)

    if (financePlans?.length) {
      const filteredPlans = financePlans.filter(plan => plan?.financeCode.indexOf(order.FinancePlan) > -1)
      if (filteredPlans?.length) {
        financePlanDetails.SiteFriendlyLabel = filteredPlans[0].siteFriendlyLabel
      }
    }
  }
  return financePlanDetails
}

export const getPaymentType = dep => {
  switch (dep.CashCode) {
    case 'MC':
      return 'Master Card'
    case 'VA':
      return 'Visa'
    case 'DV':
      return 'Discover'
    case 'AMX':
      return 'American Express'
    case 'GC':
      return 'Gift Card'
    case 'PAL':
      return 'PayPal'
    case 'AFF':
      return 'Affirm'
    default:
      return ''
  }
}
