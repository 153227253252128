import React from 'react'
import RTGLink from '@shared/link'
import { shape, string } from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { breakPoints, colors, fonts } from '@constants/styles'
import { currencyFormatUS } from '@helpers/string-helper'

const StyledListItem = styled.div`
  .order-status-product-info-headings {
    align-items: flex-start;
    border-bottom: 1px solid ${colors.lightGrey};
    padding: 0.5rem;
    .order-status-heading {
      text-align: right;
      @media only screen and (max-width: ${breakPoints.small}) {
        font-size: ${fonts.txtSmall};
        text-align: left;
      }
      p {
        font-size: ${fonts.txtSmall};
        @media only screen and (max-width: ${breakPoints.small}) {
          font-size: ${fonts.txtXsmall};
        }
      }
    }
    .subtotal {
      padding-right: 0.5rem;
      text-align: right;
      @media only screen and (max-width: ${breakPoints.small}) {
        padding-right: 0rem;
        text-align: left;
      }
    }
    .show-for-small-only {
      display: none;
      @media only screen and (max-width: ${breakPoints.small}) {
        display: block;
      }
    }
    .bold {
      font-weight: 600;
    }
  }
`

const OrderProductInfoListItem = ({ product, classname }) => {
  const { order } = useSelector(state => state.orderStatus)
  const price =
    product && (product.PackagePrice && product.PackagePrice !== '0' ? product.PackagePrice : product.UnitPrice)
  return (
    <>
      {product && (
        <StyledListItem className="grid-x cell small-12">
          <div className="grid-x cell small-12 order-status-product-info-headings">
            <div className="order-status-heading cell small-3 show-for-small-only bold">Product</div>
            <div className={`order-status-heading cell small-9 medium-4 large-7 ${classname}`}>
              {product.DisplayName || product.VendorDescription}
              <p>{`SKU: ${product.Sku}`}</p>
              {/* show ESC for standard products */}
              {product.ESCLink && order && (
                <RTGLink
                  data={{
                    target: '_blank',
                    url: product.ESCLink,
                    category: 'Product Info',
                    action: 'product esc-link click',
                    label: 'Service Contract',
                  }}
                >
                  Service Contract
                </RTGLink>
              )}
              {/* show ESC for package products */}
              {product?.ChildItems?.LineItem[0]?.ESCLink && order && (
                <RTGLink
                  data={{
                    target: '_blank',
                    url: product.ChildItems.LineItem[0].ESCLink,
                    category: 'Product Info',
                    action: 'product esc-link click',
                    label: 'Service Contract',
                  }}
                >
                  Service Contract
                </RTGLink>
              )}
            </div>
            <div className="order-status-heading cell small-3 show-for-small-only bold">Qty.</div>
            <div className="order-status-heading cell small-9 medium-2 large-1">{product.Quantity}</div>
            <div className="order-status-heading cell small-3 show-for-small-only bold">Price</div>
            <div className="order-status-heading cell small-9 medium-3 large-2">{currencyFormatUS(price)}</div>
            <div className="order-status-heading cell small-3 show-for-small-only bold">Subtotal</div>
            <div className="order-status-heading subtotal cell small-9 medium-3 large-2">
              {currencyFormatUS(price * product.Quantity)}
            </div>
          </div>
        </StyledListItem>
      )}
    </>
  )
}

OrderProductInfoListItem.propTypes = {
  product: shape({
    DisplayName: string,
    PackagePrice: string,
    Quantity: string,
    Sku: string,
    UnitPrice: string,
  }),
  classname: string,
}

export default OrderProductInfoListItem
